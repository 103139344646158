<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<cargo-form-header :modelPk="modelPk"></cargo-form-header>
		</pui-form-header>
		<cargo-modals :modelName="modelName"></cargo-modals>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="1" :href="'#events'">{{ $t('cargo.events') }}</v-tab>
				<v-tab :key="2" :href="'#clearancetimeline'" v-if="!isCreatingElement">{{ $t('cargo.clearancetimeline') }}</v-tab>
				<v-tab :key="3" :href="'#documents'" v-show="canShowDocumentsTab">{{ $t('cargo.documents') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="1" :value="'events'">
					<pui-master-detail
						componentName="vcargoclearanceactions-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ cargoclearanceid: 'cargoclearanceid' }"
						:formDisabled="formDisabled"
						:modalDialog="isModalDialog"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="2" :value="'clearancetimeline'" v-if="!isCreatingElement">
					<div class="d-flex justify-center">
						<nsw-events-template :items="itemsCEvent" :cardLeft="8" :cardRight="7"> </nsw-events-template>
					</div>
				</v-tab-item>

				<v-tab-item :key="3" :value="'documents'" v-show="canShowDocumentsTab">
					<pui-master-detail
						componentName="documentextrafields-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:showCreateBtn="showButtonsDocuments"
						:showDeleteBtn="showButtonsDocuments"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import cargoActions from './CargoActions';
import cargoModal from './CargoModals.vue';

export default {
	name: 'cargo-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'cargo-modals': cargoModal
	},
	data() {
		return {
			modelName: 'cargo',
			actions: cargoActions.gridactions,
			itemsCEvent: [],
			showButtonsDocuments: true,
			countrycode: '',
			portcode: '',
			orgsFromConsignee: []
		};
	},
	methods: {
		afterGetData() {
			this.getItems();

			if (this.model.loadingstatus == 'Unallocated' && this.model.unloadingstatus == 'Unallocated') {
				this.showButtonsDocuments = false;
			}
		},
		async getItems() {
			let items = [];

			await this.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'vcargoclearanceactions',
					page: 1,
					rows: null
				},
				(response) => {
					if (response.data && response.data.totalRecords > 0) {
						const filteredDataTab = response.data.data.filter(
							(item) =>
								item.cargoclearanceid == this.model.cargoclearanceid && (item.clearancetypeid === 7 || item.clearancetypeid === 8)
						);
						this.itemsCEvent = filteredDataTab;

						if (filteredDataTab.length > 0) {
							const locode = filteredDataTab[0].locode;
							this.countrycode = locode.substring(0, 2);
							this.portcode = locode.substring(2, 5);
							this.getConsignee(this.countrycode, this.portcode);
						} else {
							console.warn('No se encontraron datos que cumplan con el filtro para obtener locode.');
						}
					}
				},
				() => {}
			);
			return items;
		},
		getConsignee(countrycode, portcode) {
			const urlEquipmentServices2 = '/gestaut/getORGConsigneeCargoAgent';
			const params2 = {
				countrycode: countrycode,
				portcode: portcode
			};

			this.$puiRequests.getRequest(urlEquipmentServices2, params2, (response) => {
				if (response.data && response.data.length > 0) {
					this.orgsFromConsignee = response.data.map((item) => item.codorg);
				}
			});
		}
	},
	computed: {
		canShowDocumentsTab() {
			const userCodes = this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_TYPE_CODE_LIST;
			const cargoAgentIds = this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_RELATED_ORGS;

			const hasShagAndCaag = userCodes.includes('SHAG') && userCodes.includes('CAAG');

			if (hasShagAndCaag) {
				const isRelatedOrg = cargoAgentIds.includes(this.model.cargoagentid);
				if (isRelatedOrg) {
					return this.orgsFromConsignee.includes(this.model.cargoagentid);
				}
				return false;
			}
			return (
				this.$store.getters.getSession.profiles.includes('CAAG') ||
				this.$store.getters.getSession.profiles.includes('CUOF') ||
				this.$store.getters.getSession.profiles.includes('PPOF') ||
				this.$store.getters.getSession.profiles.includes('HAMA') ||
				this.$store.getters.getSession.profiles.includes('ALL')
			);
		}
	},
	created() {},
	mounted() {
		this.getItems();
	}
};
</script>
