import utilActions from '@/api/actions';

const requestClearance = {
	id: 'requestClearance',
	selectionType: 'single',
	label: 'cargo.requestClearanceAction',
	functionality: 'ACT_REQUEST_CLEARANCE_CARGO',
	checkAvailability: function (row) {
		if (!canUserPerformAction(row[0], this.$store)) {
			return false;
		}

		if (row[0].loadingstatus == 'Cargo unallocated' || row[0].unloadingstatus == 'Cargo unallocated') {
			return false;
		}
		if (
			(row[0].loadingstatus == 'Documentation pending' ||
				row[0].loadingstatus == 'Partially cleared' ||
				row[0].loadingstatus == 'Documentation rejected' ||
				row[0].unloadingstatus == 'Documentation pending' ||
				row[0].unloadingstatus == 'Partially cleared' ||
				row[0].unloadingstatus == 'Documentation rejected') &&
			row[0].status != 0 &&
			row[0].status != 3
		) {
			return true;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.registries = registries[0];
		console.log('row', row);

		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-requestClearance-' + model.name + '-show', row);
	}
};

const rejectDocumentation = {
	id: 'rejectDocumentation',
	selectionType: 'single',
	label: 'cargo.rejectDocumentationAction',
	functionality: 'ACT_REJECT_DOCUMENTATION_CARGO',
	checkAvailability: function (row) {
		if (row[0].loadingstatus == 'Cargo unallocated' || row[0].unloadingstatus == 'Cargo unallocated') {
			return false;
		}
		if (
			(row[0].loadingstatus == 'Clearance requested' ||
				row[0].unloadingstatus == 'Clearance requested' ||
				row[0].loadingstatus == 'Cargo unallocated' ||
				row[0].unloadingstatus == 'Cargo unallocated') &&
			row[0].status != 0 &&
			row[0].status != 3
		) {
			return true;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.registries = registries[0];
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-rejectDocumentation-' + model.name + '-show', row);
	}
};

const confirmClearance = {
	id: 'confirmClearance',
	selectionType: 'single',
	label: 'cargo.confirmClearanceAction',
	functionality: 'ACT_CONFIRM_CLEARANCE_CARGO',
	checkAvailability: function (row) {
		if (row[0].loadingstatus == 'Cargo unallocated' || row[0].unloadingstatus == 'Cargo unallocated') {
			return false;
		}
		if (
			(row[0].loadingstatus == 'Clearance requested' ||
				row[0].unloadingstatus == 'Clearance requested' ||
				row[0].loadingstatus == 'Cargo unallocated' ||
				row[0].unloadingstatus == 'Cargo unallocated') &&
			row[0].status != 0 &&
			row[0].status != 3
		) {
			return true;
		}
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.registries = registries[0];
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-confirmClearance-' + model.name + '-show', row);
	}
};

const rejectClearance = {
	id: 'rejectClearance',
	selectionType: 'single',
	label: 'cargo.rejectClearanceAction',
	functionality: 'ACT_CONFIRM_CLEARANCE_CARGO',
	checkAvailability: function (row) {
		if (row[0].loadingstatus == 'Cargo unallocated' || row[0].unloadingstatus == 'Cargo unallocated') {
			return false;
		}
		if (row[0].loadingstatus == 'Rejected' || row[0].unloadingstatus == 'Rejected') {
			return false;
		}
		return (
			row[0].status != 0 &&
			row[0].status != 3 &&
			(row[0].loadingstatus != 'Unallocated' || row[0].unloadingstatus != 'Unallocated') &&
			(row[0].loadingstatus != 'Documentation pending' || row[0].unloadingstatus != 'Documentation pending')
		);
	},
	runAction: function (action, model, registries) {
		const row = {};
		const objectPk = utilActions.getPkFromModel(model, registries[0]);
		row.registries = registries[0];
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-rejectClearance-' + model.name + '-show', row);
	}
};

function canUserPerformAction(row, store) {
	const userCodes = store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_TYPE_CODE_LIST;
	const cargoAgentIds = store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_RELATED_ORGS;
	const hasShagAndCaag = userCodes.includes('SHAG') && userCodes.includes('CAAG');
	const cargoAgentId = `${row.cargoagentid}`;
	const isRelatedOrg = cargoAgentIds.some((orgId) => `${orgId}` === cargoAgentId);
	return !(hasShagAndCaag && !isRelatedOrg);
}

export default {
	gridactions: [requestClearance, rejectDocumentation, confirmClearance, rejectClearance]
};
